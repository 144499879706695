import './index.css';

import gamestackTexture2Large from 'assets/ambrizaloginlarge.jpg';
import gamestackTexture2Placeholder from 'assets/ambrizaloginplace.jpg';
import gamestackTexture2 from 'assets/ambrizalogin.jpg';
import gamestackTextureLarge from 'assets/ambrizalogoutlarge2.jpg';
import gamestackTexturePlaceholder from 'assets/ambrizalogoutplace2.jpg';
import gamestackTexture from 'assets/ambrizalogout2.jpg';
import iphone11 from 'assets/iphone-11.glb';
import macbookPro from 'assets/macbook-pro.glb';
import sprTextureLarge from 'assets/Ambriza-TrainingLarge.jpg';
import sprTexturePlaceholder from 'assets/Ambriza-TrainingPlace.jpg';
import sprTexture from 'assets/Ambriza-Training.jpg';

import laCumbreLarge from 'assets/lacumbrelarge.jpg';
import laCumbrePlaceholder from 'assets/lacumbreplaceholder.jpg';
import laCumbre from 'assets/lacumbre.jpg';
import BSIInstallationLarge from 'assets/BSI-InstallationLarge.jpg';
import BSIInstallationPlaceholder from 'assets/BSI-InstallationPlace.jpg';
import BSIInstallation from 'assets/BSI-Installation.jpg';

import gonzalezParraLarge from 'assets/gonzalezParraLarge.jpg';
import gonzalezParraPlaceholder from 'assets/gonzalezParraPlace.jpg';
import gonzalezParra from 'assets/gonzalezParra.jpg';

import pisoDeportivoLarge from 'assets/pisodeportivo-large.jpg';
import pisoDeportivoPlaceholder from 'assets/pisodeportivo-placeholder.jpg';
import pisoDeportivo from 'assets/pisodeportivo.jpg';

import standardsAppsLarge from 'assets/StandardsAppsLarge.png';
import standardsAppsPlaceholder from 'assets/StandardsAppsPlace.jpg';
import standardsApps from 'assets/StandardsApps.png';
import Footer from 'components/Footer';
import { usePrefersReducedMotion, useRouteTransition } from 'hooks';
import Intro from 'pages/Home/Intro';
import Profile from 'pages/Home/Profile';
import ProjectSummary from 'pages/Home/ProjectSummary';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { ProjectSection, ProjectSectionContent } from 'components/ProjectLayout';
import { lazy, Suspense, useMemo } from 'react';
import volkiharSlide1 from 'assets/edgarweb.jpg';
import volkiharSlide1Large from 'assets/edgarweblarge.jpg';
import volkiharSlide2 from 'assets/edgarjs.jpg';
import volkiharSlide2Large from 'assets/edgarjslarge.jpg';
import volkiharSlide3 from 'assets/PlatziBasic.jpg';
import volkiharSlide3Large from 'assets/PlatziBasicLarge.jpg';
import volkiharSlidePlaceholder from 'assets/edgarwebplace.jpg';

const Carousel = lazy(() => import('components/Carousel'));

const disciplines = ['Developer', 'Creator', 'Design', 'Frontend'];

const Home = () => {
  const { status } = useRouteTransition();
  const { hash, state } = useLocation();
  const initHash = useRef(true);
  const [visibleSections, setVisibleSections] = useState([]);
  const [scrollIndicatorHidden, setScrollIndicatorHidden] = useState(false);
  const intro = useRef();
  const projectOne = useRef();
  const projectTwo = useRef();
  const projectThree = useRef();
  const projectFour = useRef();
  const projectFive = useRef();
  const projectSix = useRef();
  const projectSeven = useRef();

  const details = useRef();
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    const revealSections = [
      intro,
      projectOne,
      projectTwo,
      projectThree,
      projectFour,
      projectFive,
      projectSix,
      projectSeven,
      details,
    ];

    const sectionObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const section = entry.target;
            observer.unobserve(section);
            if (visibleSections.includes(section)) return;
            setVisibleSections(prevSections => [...prevSections, section]);
          }
        });
      },
      { rootMargin: '0px 0px -10% 0px' }
    );

    const indicatorObserver = new IntersectionObserver(
      ([entry]) => {
        setScrollIndicatorHidden(!entry.isIntersecting);
      },
      { rootMargin: '-100% 0px 0px 0px' }
    );

    revealSections.forEach(section => {
      sectionObserver.observe(section.current);
    });

    indicatorObserver.observe(intro.current);

    return () => {
      sectionObserver.disconnect();
      indicatorObserver.disconnect();
    };
  }, [visibleSections]);

  useEffect(() => {
    const hasEntered = status === 'entered';
    const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
    let scrollObserver;
    let scrollTimeout;

    const handleHashchange = (hash, scroll) => {
      clearTimeout(scrollTimeout);
      const hashSections = [intro, projectOne, details];
      const hashString = hash.replace('#', '');
      const element = hashSections.filter(item => item.current.id === hashString)[0];
      if (!element) return;
      const behavior = scroll && !prefersReducedMotion ? 'smooth' : 'instant';
      const top = element.current.offsetTop;

      scrollObserver = new IntersectionObserver(
        (entries, observer) => {
          const [entry] = entries;
          if (entry.isIntersecting) {
            scrollTimeout = setTimeout(
              () => {
                element.current.focus();
              },
              prefersReducedMotion ? 0 : 400
            );
            observer.unobserve(entry.target);
          }
        },
        { rootMargin: '-20% 0px -20% 0px' }
      );

      scrollObserver.observe(element.current);

      if (supportsNativeSmoothScroll) {
        window.scroll({
          top,
          left: 0,
          behavior,
        });
      } else {
        window.scrollTo(0, top);
      }
    };

    if (hash && initHash.current && hasEntered) {
      handleHashchange(hash, false);
      initHash.current = false;
    } else if (!hash && initHash.current && hasEntered) {
      window.scrollTo(0, 0);
      initHash.current = false;
    } else if (hasEntered) {
      handleHashchange(hash, true);
    }

    return () => {
      clearTimeout(scrollTimeout);
      if (scrollObserver) {
        scrollObserver.disconnect();
      }
    };
  }, [hash, state, prefersReducedMotion, status]);

  return (
    <div className="home">
      <Helmet>
        <title>Edgar Rincon | Developer +</title>
        <meta
          name="description"
          content="Portfolio of Edgar Rincon – a digital designer working on web &amp; mobile
          apps with a focus on motion and user experience design."
        />
        <link rel="prefetch" href={iphone11} />
        <link rel="prefetch" href={macbookPro} />
      </Helmet>
      <Intro
        id="intro"
        sectionRef={intro}
        disciplines={disciplines}
        scrollIndicatorHidden={scrollIndicatorHidden}
      />

      <ProjectSummary
        id="project-1"
        sectionRef={projectOne}
        visible={visibleSections.includes(projectOne.current)}
        index={'Wordpress'}
        title="Ambriza Training"
        description="Digital hub for restaurant staff, built with the Neve theme and Gutentor where users can manage tutorials, quizzes, calendar and team members and more"
        buttonText="View Live"
        buttonLink="https://ambrizatraining.com/"
        model={{
          type: 'laptop',
          alt: 'Ambriza Training Edgar Project',
          textures: [
            {
              src: sprTexture,
              srcSet: `${sprTexture} 800w, ${sprTextureLarge} 1440w`,
              placeholder: sprTexturePlaceholder,
            },
          ],
        }}
      />
      <ProjectSummary
        id="project-2"
        alternate
        sectionRef={projectTwo}
        visible={visibleSections.includes(projectTwo.current)}
        index={'PWA Powered'}
        title="Progressive Web Application"
        description="Featured as native applications, we created a custom installation system, some content is restricted, but here are some screenshots to give you an idea of ​​how it works. Also we made a welcome introduction video using DaVinci Resolve"
        buttonText="Play Intro"
        buttonLink="https://ambrizatraining.com/playintro/"
        model={{
          type: 'phone',
          alt: 'App login screen',
          textures: [
            {
              src: gamestackTexture,
              srcSet: `${gamestackTexture} 254w, ${gamestackTextureLarge} 508w`,
              placeholder: gamestackTexturePlaceholder,
            },
            {
              src: gamestackTexture2,
              srcSet: `${gamestackTexture2} 254w, ${gamestackTexture2Large} 508w`,
              placeholder: gamestackTexture2Placeholder,
            },
          ],
        }}
      />

      <ProjectSummary
        id="project-3"
        sectionRef={projectThree}
        visible={visibleSections.includes(projectThree.current)}
        index={'React'}
        title="Standards Apps"
        description="This small startup has been a hobby for the last three years, basically it's me and the valuable collaboration of my cousin, which means doing almost everything in each project, we have had third-party advice to improve SEO or UX / UI when it has been necessary and the experience has been fascinating"
        buttonText="View Live"
        buttonLink="https://standardsapps.com/"
        model={{
          type: 'laptop',
          alt: 'Standards Apps Edgar Rincon Dev',
          textures: [
            {
              src: standardsApps,
              srcSet: `${standardsApps} 800w, ${standardsAppsLarge} 1440w`,
              placeholder: standardsAppsPlaceholder,
            },
          ],
        }}
      />

      <ProjectSummary
        id="project-4"
        sectionRef={projectFour}
        visible={visibleSections.includes(projectFour.current)}
        index={'Wordpress'}
        title="La Cumbre Market"
        description="Ecommerce built with Woocommerce Elementor and Astra theme, pretty nice design and performance"
        buttonText="View Live"
        buttonLink="https://lacumbremarket.com/"
        model={{
          type: 'laptop',
          alt: 'La Cumbre Market Edgar Rincon Dev',
          textures: [
            {
              src: laCumbre,
              srcSet: `${laCumbre} 800w, ${laCumbreLarge} 1440w`,
              placeholder: laCumbrePlaceholder,
            },
          ],
        }}
      />

      <ProjectSummary
        id="project-5"
        sectionRef={projectFive}
        visible={visibleSections.includes(projectFive.current)}
        index={'jQuery'}
        title="BSI Installation"
        description="It was very particular project because the client had seen a website without the traditional bar menu and fell in love with the idea of ​​how compact the information was. It was achieved using jQuery but it was very challenging"
        buttonText="View Live"
        buttonLink="https://installbsi.com/"
        model={{
          type: 'laptop',
          alt: 'BSI Installation Edgar Rincon Dev',
          textures: [
            {
              src: BSIInstallation,
              srcSet: `${BSIInstallation} 800w, ${BSIInstallationLarge} 1440w`,
              placeholder: BSIInstallationPlaceholder,
            },
          ],
        }}
      />

      <ProjectSummary
        id="project-6"
        sectionRef={projectSix}
        visible={visibleSections.includes(projectSix.current)}
        index={'React'}
        title="Gonzalez Parra Law Group"
        description="My first project with the React framework, a landing page of the Lawyers Group where I grew up professionally, the smoothie effect on the scroll is incredible, unfortunately the information is a bit limited because where I come from, success brings challenges that are not very pleasant"
        buttonText="View Live"
        buttonLink="https://abgonzalezparra.com/"
        model={{
          type: 'laptop',
          alt: 'Gonzalez Parra Law Group Edgar Rincon Dev',
          textures: [
            {
              src: gonzalezParra,
              srcSet: `${gonzalezParra} 800w, ${gonzalezParraLarge} 1440w`,
              placeholder: gonzalezParraPlaceholder,
            },
          ],
        }}
      />

      <ProjectSummary
        id="project-6"
        sectionRef={projectSeven}
        visible={visibleSections.includes(projectSeven.current)}
        index={'Wordpress'}
        title="Piso Deportivo"
        description="A blog focused on sports, mostly football interviews, opinion of subscribers and content creators, a company of very bright and dedicated young people, it is built with Astra theme and Gutenberg"
        buttonText="View Live"
        buttonLink="https://pisodeportivo.com/"
        model={{
          type: 'laptop',
          alt: 'Piso Deportivo Edgar Rincon Dev',
          textures: [
            {
              src: pisoDeportivo,
              srcSet: `${pisoDeportivo} 800w, ${pisoDeportivoLarge} 1440w`,
              placeholder: pisoDeportivoPlaceholder,
            },
          ],
        }}
      />

      <Profile
        sectionRef={details}
        visible={visibleSections.includes(details.current)}
        id="about"
      />

      <ProjectSection>
        <ProjectSectionContent className="volkihar__carousel">
          <Suspense fallback={null}>
            <Carousel
              placeholder={volkiharSlidePlaceholder}
              images={useMemo(
                () => [
                  {
                    src: volkiharSlide1,
                    srcSet: `${volkiharSlide1} 960w, ${volkiharSlide1Large} 1920w`,
                    alt: 'A female character wearing the black coloured armor set.',
                  },
                  {
                    src: volkiharSlide2,
                    srcSet: `${volkiharSlide2} 960w, ${volkiharSlide2Large} 1920w`,
                    alt: 'A close up of the custom gauntlets design.',
                  },
                  {
                    src: volkiharSlide3,
                    srcSet: `${volkiharSlide3} 960w, ${volkiharSlide3Large} 1920w`,
                    alt:
                      'A female character wielding a sword and wearing the red coloured armor.',
                  },
                ],
                []
              )}
              width={1920}
              height={1080}
            />
          </Suspense>
        </ProjectSectionContent>
      </ProjectSection>

      <Footer />
    </div>
  );
};

export default Home;
